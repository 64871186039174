import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import { VerticalSection, Grid, Card } from "components/common"

import { useContentfulEdges } from "utils"

const PressGrid = ({ title, subtitle }) => {
  const { press } = useStaticQuery(PRESS_QUERY)

  const pressList = useContentfulEdges(press)

  return (
    <StyledPressGrid>
      <VerticalSection
        title={title}
        titleAs="h1"
        subtitle={subtitle}
        grid={() => (
          <Grid
            toEdge
            col={3}
            gap={64}
            width="320px"
            list={pressList}
            component={({
              url,
              pressImage,
              pressPublisher,
              pressTitle,
              pressDate,
            }) => (
              <Card
                href={url}
                target="_blank"
                media={{ desktop: pressImage }}
                hat={pressPublisher}
                title={pressTitle}
                subtitle={pressDate}
              />
            )}
          />
        )}
      />
    </StyledPressGrid>
  )
}

const StyledPressGrid = styled.div`
  --section-padding-top: var(--sp-nav);
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --tg-hat-color: var(--spruce);
  --tg-subtitle-color: var(--dirt);

  .hat,
  .subtitle {
    font-weight: 500;
  }

  .gridWrap {
    max-width: 1080px;
    align-self: center;

    ul {
      grid-gap: var(--sp-64);
    }
  }
`

export const PRESS_QUERY = graphql`
  {
    press: allContentfulPress(sort: { order: DESC, fields: pressDate }) {
      edges {
        node {
          ...Press
        }
      }
    }
  }
`

export default PressGrid
