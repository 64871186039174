import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, Nav } from "components/common"
import { useContentfulSection, useMeta } from "utils"

import { PressGrid } from "../sections/press"

import { CtaGetDemo } from "components/cta"

const PressPage = ({ data: { press } }) => {
  const { press_grid, cta_get_demo } = useContentfulSection(press.sections)

  const meta = useMeta(press)

  return (
    <Layout>
      <Seo {...meta} />
      <Wrap>
        <Nav />
        <PressGrid {...press_grid} />
        <CtaGetDemo {...cta_get_demo} />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div``

export const query = graphql`
  {
    press: contentfulPage(pageId: { eq: "press" }) {
      ...Page
    }
  }
`

export default PressPage
